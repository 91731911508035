// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { generatePath, parsePath } from 'react-router';
import { redirect, matchRoutes } from 'react-router-dom';
import { gql } from '@apollo/client';
import { api, emptyFn, prepareUrl } from 'jsw';
import { addToast } from 'helpers/toasts';
import { isNetworkError } from 'helpers/isNetworkError';
import pageData from '../helpers/pageData';
import routesTransformer from '../helpers/routesTransformer';
import apolloClient from '../apolloClient';
import AppLayout from '../components/layouts/AppLayout';
import CpPagesLayout from '../components/layouts/CpPagesLayout';
import PhpPagesLayout from '../components/layouts/PhpPagesLayout';
import AppBoundary from '../components/AppBoundary';
import LogsPage from '../components/pages/LogsPage';
import { odbcServerRoute } from 'routes/odbcServerRoute';
import { odbcDomainRoute } from 'routes/odbcDomainRoute';
import domainOverviewRoutes from 'routes/domainOverviewRoutes';

const initialDataLoader = () => {
    const { isInitial, ...data } = pageData();
    pageData(data);

    return isInitial ? data : null;
};

const clientSummaryReportLoader = async ({ params: { clientId }, request }) => {
    const url = new URL(request.url);
    const layoutId = url.searchParams.get('layoutId') || '';
    await apolloClient.query({
        query: require('queries/ClientSummaryReport.graphql'),
        variables: { clientId: btoa(`Client:${clientId}`), layoutId: layoutId ? parseInt(layoutId) : '' },
    });
};

const serverSummaryReportLoader = async ({ request }) => {
    const url = new URL(request.url);
    const layoutId = url.searchParams.get('layoutId') || '';
    await apolloClient.query({
        query: require('queries/SummaryReportPage.graphql'),
        variables: { layoutId: layoutId ? parseInt(layoutId) : '' },
    });
};

const createPageDataLoader = dataPath => async ({ request, params }) => {
    const url = new URL(request.url);
    if (dataPath) {
        url.pathname = generatePath(dataPath, params);
    }

    let result;
    try {
        result = initialDataLoader() ?? await api.get(url.toString());
    } catch (error) {
        if (isNetworkError(error)) {
            addToast({
                intent: 'danger',
                message: `${error.message}: ${url.toString()}`,
            });
            return new Promise(emptyFn);
        }
        throw error;
    }
    const { graphqlQueries = [], ...newData } = result;

    if (newData.redirect) {
        const url = prepareUrl(newData.redirect);
        if (isClientSideRedirectAllowed(url)) {
            return redirect(url);
        }

        window.location.replace(url);
        return new Promise(emptyFn);
    }

    graphqlQueries.forEach(({ query, ...options }) => {
        try {
            apolloClient.writeQuery({
                query: gql(query),
                ...options,
            });
        } catch { }
    });

    pageData(newData);

    return newData;
};

const ipAddressDomainRoute = {
    path: ':ipAddressId/domains',
    componentLoader: () => import(/* webpackChunkName: "components/pages/IpAddressDomainsPage" */'components/pages/IpAddressDomainsPage'),
    loader: async ({ params }) => {
        const clientId = params.clientId ? btoa(`Client:${params.clientId}`) : null;
        const { data } = await apolloClient.query({
            query: require('queries/IpAddressDomainsPage.graphql'),
            variables: { ipAddressId: params.ipAddressId, clientId },
        });
        return data;
    },
};

const changeOwnerRoute = {
    path: 'change-owner',
    componentLoader: () => import(/* webpackChunkName: "components/pages/ChangeOwnerPage" */'components/pages/ChangeOwnerPage'),
    loader: async ({ request }) => {
        const url = new URL(request.url);
        const ids = [...url.searchParams.entries()].map(([, value]) => btoa(`Domain:${value}`));

        const { data } = await apolloClient.query({
            query: require('queries/PrepareChangeSubscriptionsOwner.graphql'),
            variables: { ids },
        });

        return data;
    },
};

const isTsSessionsExist = async () => {
    const { data: { viewer: { type, permissions: { manageServerSessions } }, os: { isWindows } } } = await apolloClient.query({
        query: gql`
            query {
                viewer {
                    login
                    type
                    permissions {
                        manageServerSessions
                    }
                }
                os {
                    isWindows
                }
            }
        `,
    });

    return type === 'ADMIN' && isWindows && manageServerSessions;
};

const isAspPageExist = async () => {
    const { data: { viewer: { permissions: { manageAspNetSettings } }, capability: { packages: { isASPdotNetInstalled } } },
    } = await apolloClient.query({
        query: gql`
            query {
                viewer {
                    login
                    permissions {
                        manageAspNetSettings
                    }
                }
                capability {
                    packages {
                        isASPdotNetInstalled
                    }
                }
            }
        `,
    });

    return isASPdotNetInstalled && manageAspNetSettings;
};


const routes = routesTransformer([{
    element: <AppLayout />,
    errorElement: <AppBoundary />,
    children: [
        {
            path: ['/login_up.php', '/login_up.php3', '/login'],
            componentLoader: () => import(/* webpackMode: "eager" */'components/pages/LoginPage'),
            loader: async () => {
                await apolloClient.query({
                    query: require('queries/LoginPage.graphql'),
                });
                return initialDataLoader();
            },
            children: [{
                path: '*',
                loader: () => redirect('..'),
            }],
        },
        {
            path: '/get_password.php',
            componentLoader: () => import(/* webpackMode: "eager" */'components/pages/GetPasswordPage'),
            loader: initialDataLoader,
        },
        {
            path: '/ch_pass_by_secret.php',
            componentLoader: () => import(/* webpackMode: "eager" */'components/pages/RestorePasswordPage'),
        },
        {
            path: '/admin/force-reset-password',
            componentLoader: () => import(/* webpackChunkName: "views/admin/force-reset-password" */'views/admin/force-reset-password'),
            loader: createPageDataLoader('/admin/force-reset-password'),
        },
        {
            path: '/admin/setup/progress',
            componentLoader: () => import(/* webpackChunkName: "views/admin/setup/progress" */'views/admin/setup/progress'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/setup/*',
            componentLoader: () => import(/* webpackChunkName: "views/admin/setup/index" */'views/admin/setup/index'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/home/*',
            componentLoader: () => import(/* webpackChunkName: "views/admin/home/admin" */'views/admin/home/admin'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/app',
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/app/info/id/:id',
            componentLoader: () => import(/* webpackChunkName: "views/admin/app/info" */'views/admin/app/info'),
            loader: createPageDataLoader('/admin/app/info/id/:id'),
        },
        {
            path: '/admin/app/usage',
            componentLoader: () => import(/* webpackChunkName: "views/admin/app/usage" */'views/admin/app/usage'),
            loader: createPageDataLoader('/admin/app/usage'),
        },
        {
            path: ['/admin/app/uploaded', '/admin/app/uploaded/downloadStatus/:status'],
            componentLoader: () => import(/* webpackChunkName: "views/admin/app/uploaded" */'views/admin/app/uploaded'),
            loader: createPageDataLoader('/admin/app/uploaded'),
        },
        {
            path: '/admin/custom-buttons/list',
            componentLoader: () => import(/* webpackChunkName: "views/admin/custom-buttons/list" */'views/admin/custom-buttons/list'),
            loader: createPageDataLoader('/admin/custom-buttons/list'),
        },
        {
            path: '/smb/custom-buttons/list',
            componentLoader: () => import(/* webpackChunkName: "views/admin/custom-buttons/list" */'views/admin/custom-buttons/list'),
            loader: createPageDataLoader('/smb/custom-buttons/list'),
        },
        {
            path: '/admin/ftp-sessions/list',
            componentLoader: () => import(/* webpackChunkName: "views/admin/ftp-sessions/list" */'views/admin/ftp-sessions/list'),
            loader: createPageDataLoader('/admin/ftp-sessions/list'),
        },
        {
            path: '/admin/php-handler/list',
            componentLoader: () => import(/* webpackChunkName: "views/admin/php-handler/list" */'views/admin/php-handler/list'),
            loader: createPageDataLoader('/admin/php-handler/list'),
        },
        {
            path: ['/admin/scheduler', '/smb/scheduler'],
            loader: createPageDataLoader(),
        },
        {
            path: ['/admin/backup/schedule', '/smb/backup/schedule', '/smb/backup/schedule/domainId/:domainId'],
            componentLoader: () => import(/* webpackChunkName: "components/pages/ScheduledBackupPage" */'components/pages/ScheduledBackupPage'),
            loader: createPageDataLoader(),
        },
        {
            path: [
                '/admin/backup/schedule-list',
                '/admin/backup/schedule/type/:scheduleType',
                '/admin/backup/schedule/domainId/:domainId/type/:scheduleType',
                '/smb/backup/schedule-list',
                '/smb/backup/schedule/type/:scheduleType',
                '/smb/backup/schedule/domainId/:domainId/type/:scheduleType',
                '/smb/backup/schedule-list/domainId/:domainId',
            ],
            componentLoader: () => import(/* webpackChunkName: "components/pages/ScheduledBackupListPage" */'components/pages/ScheduledBackupListPage'),
            loader: createPageDataLoader(),
        },
        {
            path: ['/admin/scheduler/tasks-list', '/smb/scheduler/tasks-list'],
            componentLoader: () => import(/* webpackChunkName: "views/default/scheduler/list" */'views/default/scheduler/list'),
            loader: createPageDataLoader(),
        },
        {
            path: ['/admin/domain/list', '/admin/domain/add-domain'],
            componentLoader: () => import(/* webpackChunkName: "views/admin/domain/list" */'components/pages/DomainsPage'),
            loader: createPageDataLoader('/admin/domain/list'),
        },
        {
            path: ['/admin/customer/list', '/admin/customer/create'],
            componentLoader: () => import(/* webpackChunkName: "views/admin/customer/list" */'components/pages/CustomersPage'),
            loader: createPageDataLoader('/admin/customer/list'),
        },
        {
            path: '/admin/customer/overview/id/:id',
            loader: createPageDataLoader(),
        },
        {
            path: ['/admin/customer/domains/id/:id', '/admin/customer/add-domain/id/:id'],
            componentLoader: () => import(/* webpackChunkName: "views/admin/customer/domains" */'components/pages/CustomerDomainsPage'),
            loader: createPageDataLoader('/admin/customer/domains/id/:id'),
        },
        {
            path: ['/admin/customer/subscriptions/id/:id', '/admin/customer/new-subscription/id/:id'],
            componentLoader: () => import(/* webpackChunkName: "views/admin/customer/subscriptions" */'components/pages/CustomerSubscriptionsPage'),
            loader: createPageDataLoader('/admin/customer/subscriptions/id/:id'),
            children: [changeOwnerRoute],
        },
        {
            path: '/admin/reseller/list',
            componentLoader: () => import(/* webpackChunkName: "views/admin/reseller/list" */'components/pages/ResellersPage'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/reseller/overview/id/:id',
            loader: createPageDataLoader(),
        },
        {
            path: ['/admin/reseller/customers/id/:id', '/admin/reseller/new-customer/id/:id'],
            componentLoader: () => import(/* webpackChunkName: "views/admin/reseller/customers" */'components/pages/ResellerCustomersPage'),
            loader: createPageDataLoader('/admin/reseller/customers/id/:id'),
        },
        {
            path: ['/admin/reseller/domains/id/:id', '/admin/reseller/add-domain/id/:id'],
            componentLoader: () => import(/* webpackChunkName: "views/admin/reseller/domains" */'components/pages/ResellerDomainsPage'),
            loader: createPageDataLoader('/admin/reseller/domains/id/:id'),
        },
        {
            path: ['/admin/reseller/subscriptions/id/:id', '/admin/reseller/new-subscription/id/:id'],
            componentLoader: () => import(/* webpackChunkName: "views/admin/reseller/subscriptions" */'components/pages/ResellerSubscriptionsPage'),
            loader: createPageDataLoader('/admin/reseller/subscriptions/id/:id'),
            children: [changeOwnerRoute],
        },
        {
            path: '/admin/server/tools',
            componentLoader: () => import(/* webpackChunkName: "views/admin/server/tools" */'views/admin/server/tools'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/server/preview-domain-settings',
            componentLoader: () => import(/* webpackChunkName: "views/admin/server/preview-domain-settings"*/'views/admin/server/preview-domain-settings'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/services/list',
            componentLoader: () => import(/* webpackChunkName: "views/admin/services/list" */'views/admin/services/list'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/sessions/list',
            componentLoader: () => import(/* webpackChunkName: "views/admin/sessions/list" */'views/admin/sessions/list'),
            loader: createPageDataLoader(),
        },
        {
            path: ['/admin/subscription/list', '/admin/subscription/create'],
            componentLoader: () => import(/* webpackChunkName: "views/admin/subscription/list" */'components/pages/SubscriptionsPage'),
            loader: createPageDataLoader('/admin/subscription/list'),
            children: [changeOwnerRoute],
        },
        {
            path: ['/admin/subscription/login/all/:all/id/:id/', '/admin/subscription/login/id/:id/'],
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/subscription/overview/id/:id/',
            loader: createPageDataLoader(),
        },
        {
            path: ['/smb/file-manager/list', '/smb/file-manager/list/domainId/:domainId'],
            componentLoader: () => import(/* webpackChunkName: "views/smb/file-manager/list" */'views/smb/file-manager/list'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/file-manager',
            componentLoader: () => import(/* webpackChunkName: "views/smb/file-manager/index" */'views/smb/file-manager/index'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/my-service',
            componentLoader: () => import(/* webpackChunkName: "views/smb/my-service/index" */'views/smb/my-service/index'),
            loader: createPageDataLoader('/smb/my-service'),
        },
        {
            path: ['/smb/email-address', '/smb/email-address/index', '/smb/email-address/index/domainId/:domainId'],
            componentLoader: () => import(/* webpackChunkName: "views/smb/email-address/index" */'views/smb/email-address/index'),
            loader: createPageDataLoader(),
        },
        {
            path: ['/smb/email-address/list', '/smb/email-address/list/domainId/:domainId'],
            componentLoader: () => import(/* webpackChunkName: "views/smb/email-address/list" */'views/smb/email-address/list'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/mail-settings/list',
            componentLoader: () => import(/* webpackChunkName: "views/smb/mail-settings/list" */'views/smb/mail-settings/list'),
            loader: createPageDataLoader(),
        },
        {
            path: ['/smb/account', '/smb/account/index'],
            componentLoader: () => import(/* webpackChunkName: "views/smb/account/index" */'views/smb/account/index'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/account/show/id/:id',
            componentLoader: () => import(/* webpackChunkName: "views/smb/account/show" */'views/smb/account/show'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/user/index',
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/user/list',
            componentLoader: () => import(/* webpackChunkName: "views/smb/user/list" */'components/pages/UsersPage'),
            loader: createPageDataLoader(),
        },
        {
            path: ['/smb/web/setup', '/smb/web/add-domain/create-webspace/true'],
            componentLoader: () => import(/* webpackChunkName: "views/smb/web/setup" */'views/smb/web/setup'),
            loader: createPageDataLoader('/smb/web/setup'),
        },
        {
            path: ['/smb/web/view', '/smb/web/view/id/:id/type/:type', '/smb/web/add-domain'],
            componentLoader: () => import(/* webpackChunkName: "views/smb/web/view" */'views/smb/web/view'),
            loader: createPageDataLoader('/smb/web/view'),
            children: [
                changeOwnerRoute,
                ...domainOverviewRoutes.map(({ path, ...params }) => ({
                    path: `:id/${path}`,
                    ...params,
                })),
            ],
        },
        {
            path: '/smb/web/overview/id/:id/type/:type',
            componentLoader: () => import(/* webpackChunkName: "views/smb/web/view" */'views/smb/web/view'),
            loader: createPageDataLoader(),
            children: domainOverviewRoutes,
        },
        {
            path: '/smb/web/php-settings/id/:id',
            componentLoader: () => import(/* webpackChunkName: "views/smb/web/php-settings" */'views/smb/web/php-settings'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/statistics/details',
            componentLoader: () => import(/* webpackChunkName: "views/smb/statistics/details" */'views/smb/statistics/details'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/statistics/list',
            componentLoader: () => import(/* webpackChunkName: "views/smb/statistics/details" */'views/smb/statistics/list'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/statistics',
            loader: createPageDataLoader(),
        },
        {
            path: ['/smb/account/switch/all/:all/id/:id', '/smb/account/switch/id/:id'],
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/database',
            componentLoader: () => import(/* webpackChunkName: "views/smb/database/index" */'views/smb/database/index'),
            loader: createPageDataLoader(),
        },
        {
            path: ['/smb/database/list', '/smb/database/list/domainId/:domainId'],
            componentLoader: () => import(/* webpackChunkName: "views/smb/database/list" */'views/smb/database/list'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/database/users-list/domainId/:domainId',
            componentLoader: () => import(/* webpackChunkName: "views/smb/database/users-list" */'views/smb/database/users-list'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/task-manager',
            componentLoader: () => import(/* webpackChunkName: "views/admin/task-manager/index" */'views/admin/task-manager/index'),
            loader: createPageDataLoader(),
        },
        {
            path: '/cp/dns',
            children: [
                {
                    index: true,
                    loader: () => redirect('records'),
                },
                {
                    path: 'records',
                    componentLoader: () => import(/* webpackChunkName: "views/dns/records" */'components/pages/DnsSettingsPage/Records'),
                    loader: () => apolloClient.query({
                        query: require('queries/DnsRecordsPage.graphql'),
                    }).then(({ data }) => data),
                    isExist: async () => {
                        const { data: { viewer: { type, permissions }, mode: { isDnsServiceSupported } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerDnsTemplate
                                        }
                                    }
                                    mode {
                                        isDnsServiceSupported
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && permissions.manageServerDnsTemplate && isDnsServiceSupported;
                    },
                    children: [{
                        path: ['dns-soa-zone-sync', 'dns-restore'],
                    }, {
                        path: ':id/edit',
                        componentLoader: () => import(/* webpackChunkName: "views/dns/records/edit" */'components/pages/DnsSettingsPage/Records/DnsRecordFormDrawer'),
                        loader: ({ params: { id } }) => apolloClient.query({
                            query: require('components/pages/DnsSettingsPage/Records/DnsRecordFormDrawer/EditDnsRecord.graphql'),
                            variables: { id },
                        }),
                    }, {
                        path: 'add',
                        componentLoader: () => import(/* webpackChunkName: "views/dns/records/edit" */'components/pages/DnsSettingsPage/Records/DnsRecordFormDrawer'),
                        loader: () => apolloClient.query({
                            query: require('components/pages/DnsSettingsPage/Records/DnsRecordFormDrawer/AddDnsRecord.graphql'),
                        }),
                    }, {
                        path: 'dns-zone-sync',
                        componentLoader: () => import(/* webpackChunkName: "views/dns/records/dns-zone-sync" */'components/pages/DnsSettingsPage/Records/DnsZoneSyncDrawer'),
                    }],
                },
                {
                    path: 'soa-record',
                    componentLoader: () => import(/* webpackChunkName: "views/dns/soa-record" */'components/pages/DnsSettingsPage/SoaRecord'),
                    loader: async () => {
                        await apolloClient.query({
                            query: require('queries/DnsSoaRecordsPage.graphql'),
                        });
                    },
                    isExist: async () => {
                        const { data: { viewer: { type, permissions }, mode: { isDnsServiceSupported } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerDnsTemplate
                                        }
                                    }
                                    mode {
                                        isDnsServiceSupported
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && permissions.manageServerDnsTemplate && isDnsServiceSupported;
                    },
                },
            ],
        },
        {
            path: '/admin/dns/acl-records',
            componentLoader: () => import(/* webpackChunkName: "views/admin/dns/acl-records" */'views/admin/dns/acl-records'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/dns/add-acl-record',
            componentLoader: () => import(/* webpackChunkName: "views/admin/dns/add-acl-record" */'views/admin/dns/add-acl-record'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/dns/external-dns',
            componentLoader: () => import(/* webpackChunkName: "views/admin/dns/external-dns" */'views/admin/dns/external-dns'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/dns/external-dns-edit/extensionId/:extensionId',
            componentLoader: () => import(/* webpackChunkName: "views/admin/dns/external-dns-edit" */'views/admin/dns/external-dns-edit'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/dns-zone/index/id/:id/type/:type',
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/dns-zone/records-list/id/:id/type/:type',
            componentLoader: () => import(/* webpackChunkName: "views/smb/dns-zone/records-list" */'views/smb/dns-zone/records-list'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/dns-zone/soa-record/id/:id/type/:type',
            componentLoader: () => import(/* webpackChunkName: "views/smb/dns-zone/soa-record" */'views/smb/dns-zone/soa-record'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/dns-zone/acl-records/id/:id/type/:type',
            componentLoader: () => import(/* webpackChunkName: "views/smb/dns-zone/acl-records" */'views/smb/dns-zone/acl-records'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/dns-zone/add-acl-record/id/:id/type/:type',
            componentLoader: () => import(/* webpackChunkName: "views/smb/dns-zone/add-acl-record" */'views/smb/dns-zone/add-acl-record'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/dns-zone/external-dns/id/:id/type/:type',
            componentLoader: () => import(/* webpackChunkName: "views/smb/dns-zone/external-dns" */'views/smb/dns-zone/external-dns'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/dns-zone/external-dns-edit/id/:id/type/:type/extensionId/:extensionId',
            componentLoader: () => import(/* webpackChunkName: "views/smb/dns-zone/external-dns-edit" */'views/smb/dns-zone/external-dns-edit'),
            loader: createPageDataLoader(),
        },
        {
            path: ['/cp/domains/:domainId/bandwidth-limiting'],
            componentLoader: () => import(/* webpackChunkName: "components/pages/BandwidthLimitingPage" */'components/pages/BandwidthLimitingPage'),
            loader: async ({ params }) => {
                const { data } = await apolloClient.query({
                    query: require('queries/BandwidthLimitingPage.graphql'),
                    variables: { id: btoa(`Domain:${params.domainId}`) },
                });
                return data;
            },
        },
        {
            path: ['/cp/domains/:domainId/hotlink-protection'],
            componentLoader: () => import(/* webpackChunkName: "components/pages/HotlinkProtectionPage" */'components/pages/HotlinkProtectionPage'),
            loader: async ({ params }) => {
                const { data } = await apolloClient.query({
                    query: require('queries/HotlinkProtectionPage.graphql'),
                    variables: { id: btoa(`Domain:${params.domainId}`) },
                });
                return data;
            },
        },
        {
            path: '/cp/settings-ui/edit-interface-view',
            componentLoader: () => import(/* webpackChunkName: "views/admin/settings-ui/edit-interface-view" */'views/admin/settings-ui/edit-interface-view'),
            loader: () => apolloClient.query({
                query: require('queries/EditInterfaceViewPage.graphql'),
            }).then(({ data }) => data),
            isExist: async () => {
                const { data: { viewer: { type }, mode } } = await apolloClient.query({
                    query: gql`
                        query {
                            viewer {
                                login
                                type
                            }
                            mode {
                                isViewSwitcherAllowed
                            }
                        }
                    `,
                });

                return type === 'ADMIN' && mode.isViewSwitcherAllowed;
            },
        },
        {
            path: '/admin/settings-ui/list-languages',
            componentLoader: () => import(/* webpackChunkName: "views/admin/settings-ui/list-languages" */'views/admin/settings-ui/list-languages'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/settings-ui',
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/database/settings',
            componentLoader: () => import(/* webpackChunkName: "views/admin/database/settings"*/'views/admin/database/settings'),
            loader: createPageDataLoader(),
        },
        {
            path: '/smb/web/web-server-settings/id/:id',
            componentLoader: () => import(/* webpackChunkName: "views/smb/web/web-server-settings" */'views/smb/web/web-server-settings'),
            loader: createPageDataLoader(),
        },
        {
            path: '/admin/notifications',
            children: [
                {
                    index: true,
                    loader: () => redirect('email-settings'),
                },
                {
                    path: 'email-settings',
                    componentLoader: () => import(/* webpackChunkName: "views/admin/notifications/index" */'views/admin/notifications/index'),
                    loader: createPageDataLoader(),
                },
            ],
        },
        {
            path: '/cp/notifications/panel-settings',
            componentLoader: () => import(/* webpackChunkName: "components/pages/PanelNotificationsSettings" */'components/pages/Notifications/PanelNotificationsSettings'),
            loader: () => apolloClient.query({
                query: require('queries/NotificationUiSetting.graphql'),
            }).then(({ data }) => data),
            isExist: async () => {
                const { data: { viewer: { type, permissions } } } = await apolloClient.query({
                    query: gql`
                        query {
                            viewer {
                                login
                                type
                                permissions {
                                    manageServerNotifications
                                }
                            }
                        }
                    `,
                });

                return type === 'ADMIN' && permissions.manageServerNotifications;
            },
        },
        {
            path: '/cp/settings-ui/branding',
            componentLoader: () => import(/* webpackChunkName: "components/pages/BrandingPage" */'components/pages/BrandingPage'),
            loader: () => apolloClient.query({
                query: require('queries/BrandingPage.graphql'),
            }).then(({ data }) => data),
            isExist: async () => {
                const { data: { viewer: { type, permissions } } } = await apolloClient.query({
                    query: gql`
                        query {
                            viewer {
                                login
                                type
                                permissions {
                                    manageServerBranding
                                }
                            }
                        }
                    `,
                });

                return (type === 'ADMIN' && permissions.manageServerBranding) || type === 'RESELLER';
            },
        },
        {
            path: '/cp/server/db',
            children: [
                {
                    index: true,
                    componentLoader: () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServersPage'),
                    loader: () => apolloClient.query({
                        query: require('queries/DatabaseServersPage.graphql'),
                    }).then(({ data }) => data),
                    isExist: async () => {
                        const { data: { viewer: { type, permissions } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerDb
                                        }
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && permissions.manageServerDb;
                    },
                },
                {
                    path: 'create',
                    componentLoader: () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServerCreatePage'),
                    loader: () => apolloClient.query({
                        query: require('queries/DatabaseServerCreatePage.graphql'),
                    }).then(({ data }) => data),
                    isExist: async () => {
                        const { data: { viewer: { type, permissions } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerDb
                                        }
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && permissions.manageServerDb;
                    },
                },
                {
                    path: ':id',
                    children: [
                        {
                            index: true,
                            loader: () => redirect('properties'),
                        },
                        {
                            path: 'properties',
                            componentLoader: () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServerPropertiesPage'),
                            loader: ({ params: { id } }) => apolloClient.query({
                                query: require('queries/DatabaseServerPropertiesPage.graphql'),
                                variables: { id: btoa(`DatabaseServer:${id}`) },
                            }).then(({ data }) => data),
                            isExist: async ({ params }) => {
                                const { data: { viewer: { type, permissions }, databaseServer } } = await apolloClient.query({
                                    query: gql`
                                        query ($id: ID!) {
                                            viewer {
                                                login
                                                type
                                                permissions {
                                                    manageServerDb
                                                }
                                            }
                                            databaseServer: node(id: $id) {
                                                id
                                            }
                                        }
                                    `,
                                    variables: {
                                        id: btoa(`DatabaseServer:${params.id}`),
                                    },
                                    errorPolicy: 'ignore',
                                });

                                return Boolean(type === 'ADMIN' && permissions.manageServerDb && databaseServer);
                            },
                        },
                        {
                            path: 'databases',
                            componentLoader: () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServerDatabasesPage'),
                            loader: ({ params: { id } }) => apolloClient.query({
                                query: require('queries/DatabaseServerDatabasesPage.graphql'),
                                variables: { id: btoa(`DatabaseServer:${id}`) },
                            }).then(({ data }) => data),
                            isExist: async ({ params }) => {
                                const { data: { viewer: { type, permissions }, databaseServer } } = await apolloClient.query({
                                    query: gql`
                                        query ($id: ID!) {
                                            viewer {
                                                login
                                                type
                                                permissions {
                                                    manageServerDb
                                                }
                                            }
                                            databaseServer: node(id: $id) {
                                                id
                                            }
                                        }
                                    `,
                                    variables: {
                                        id: btoa(`DatabaseServer:${params.id}`),
                                    },
                                    errorPolicy: 'ignore',
                                });

                                return Boolean(type === 'ADMIN' && permissions.manageServerDb && databaseServer);
                            },
                        },
                        {
                            path: 'change-password',
                            componentLoader: () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServerChangePasswordPage'),
                            loader: ({ params: { id } }) => apolloClient.query({
                                query: require('queries/DatabaseServerChangePasswordPage.graphql'),
                                variables: { id: btoa(`DatabaseServer:${id}`) },
                            }).then(({ data }) => data),
                            isExist: async ({ params }) => {
                                const { data: { viewer: { type, permissions }, os, mode, databaseServer } } = await apolloClient.query({
                                    query: gql`
                                        query ($id: ID!) {
                                            viewer {
                                                login
                                                type
                                                permissions {
                                                    manageServerDb
                                                }
                                            }
                                            os {
                                                isWindows
                                            }
                                            mode {
                                                isDemo
                                            }
                                            databaseServer: node(id: $id) {
                                                id
                                            }
                                        }
                                    `,
                                    variables: {
                                        id: btoa(`DatabaseServer:${params.id}`),
                                    },
                                    errorPolicy: 'ignore',
                                });

                                return Boolean(type === 'ADMIN' && permissions.manageServerDb && os.isWindows && !mode.isDemo && databaseServer);
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: ['/cp/websites-diagnostic', '/cp/websites-diagnostic/id/:domainId'],
            componentLoader: () => import(/* webpackChunkName: "views/admin/websites-diagnostic/index" */'components/pages/WebsitesDiagnostic'),
            loader: async () => {
                await apolloClient.query({
                    query: require('queries/WebsitesDiagnosticPage.graphql'),
                    fetchPolicy: 'network-only',
                });
            },
            isExist: async () => {
                const { data: { viewer, config } } = await apolloClient.query({
                    query: gql`
                        query {
                            viewer {
                                login
                                type
                            }
                            config {
                                websitesDiagnostic {
                                    enabled
                                }
                            }
                        }
                    `,
                });

                return viewer.type === 'ADMIN' && config.websitesDiagnostic.enabled;
            },
        },
        {
            path: '/cp/system-time',
            componentLoader: () => import(/* webpackChunkName: "components/pages/SystemTimePage" */'components/pages/SystemTimePage'),
            loader: () => apolloClient.query({
                query: require('queries/SystemTimePage.graphql'),
            }).then(({ data }) => data),
        },
        {
            path: '/cp/server/mail',
            children: [
                {
                    index: true,
                    loader: () => redirect('settings'),
                },
                {
                    path: 'settings',
                    componentLoader: () => import(/* webpackChunkName: "views/server/mail" */'components/pages/ServerMailSettingsPage'),
                    loader: async () => {
                        await apolloClient.query({
                            query: require('queries/ServerMailSettingsPage.graphql'),
                        });
                        await apolloClient.query({
                            query: require('queries/ServerMailTabs.graphql'),
                        });
                    },
                    isExist: async () => {
                        const { data: { viewer: { type, permissions } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerMail
                                            manageServerMailAutodiscover
                                            manageServerVirusProtection
                                            manageServerSpamFilter
                                        }
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && (
                            permissions.manageServerMail ||
                            permissions.manageServerMailAutodiscover ||
                            permissions.manageServerVirusProtection ||
                            permissions.manageServerSpamFilter
                        );
                    },
                },
                {
                    path: 'black-list',
                    componentLoader: () => import(/* webpackChunkName: "views/server/mail" */'components/pages/ServerMailBlackListPage'),
                    loader: async () => {
                        await apolloClient.query({
                            query: require('queries/ServerMailBlackListPage.graphql'),
                        });
                        await apolloClient.query({
                            query: require('queries/ServerMailTabs.graphql'),
                        });
                    },
                    isExist: async () => {
                        const { data: { viewer: { type, permissions } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerMailBlackWhiteLists
                                        }
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && permissions.manageServerMailBlackWhiteLists;
                    },
                },
                {
                    path: 'white-list',
                    componentLoader: () => import(/* webpackChunkName: "views/server/mail" */'components/pages/ServerMailWhiteListPage'),
                    loader: async () => {
                        await apolloClient.query({
                            query: require('queries/ServerMailWhiteListPage.graphql'),
                        });
                        await apolloClient.query({
                            query: require('queries/ServerMailTabs.graphql'),
                        });
                    },
                    isExist: async () => {
                        const { data: { viewer: { type, permissions } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerMailBlackWhiteLists
                                        }
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && permissions.manageServerMailBlackWhiteLists;
                    },
                },
                {
                    path: 'antivirus',
                    componentLoader: () => import(/* webpackChunkName: "views/server/mail" */'components/pages/ServerMailAntivirusPage'),
                    loader: async () => {
                        await apolloClient.query({
                            query: require('queries/ServerMailAntivirusPage.graphql'),
                        });
                        await apolloClient.query({
                            query: require('queries/ServerMailTabs.graphql'),
                        });
                    },
                    isExist: async () => {
                        const { data: { viewer: { type, permissions } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerVirusProtection
                                        }
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && permissions.manageServerVirusProtection;
                    },
                },
                {
                    path: 'spam-filter',
                    componentLoader: () => import(/* webpackChunkName: "views/server/mail" */'components/pages/ServerMailSpamFilterPage'),
                    loader: async () => {
                        await apolloClient.query({
                            query: require('queries/ServerMailSpamFilterPage.graphql'),
                        });
                        await apolloClient.query({
                            query: require('queries/ServerMailTabs.graphql'),
                        });
                    },
                    isExist: async () => {
                        const { data: { viewer: { type, permissions }, os: { isWindows } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerSpamFilter
                                        }
                                    }
                                    os {
                                        isWindows
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && permissions.manageServerSpamFilter && isWindows;
                    },
                },
            ],
        },
        {
            path: '/cp/server/summary-report',
            componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportPage" */'components/pages/SummaryReportPage'),
            loader: serverSummaryReportLoader,
        },
        {
            path: '/cp/server/summary-report/layouts',
            componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportLayoutsPage" */'components/pages/SummaryReportLayoutsPage'),
            loader: async () => {
                await apolloClient.query({
                    query: require('queries/SummaryReportLayoutsPage.graphql'),
                });
            },
        },
        {
            path: '/cp/server/summary-report/traffic-history',
            componentLoader: () => import(/* webpackChunkName: "components/pages/TrafficHistoryReportPage" */'components/pages/TrafficHistoryReportPage'),
            loader: async () => {
                await apolloClient.query({
                    query: require('queries/TrafficHistoryReportPage.graphql'),
                });
            },
        },
        {
            path: '/cp/server/summary-report/print',
            componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportPage/SummaryReportPrintPage" */
                'components/pages/SummaryReportPage/SummaryReportPrintPage'),
            loader: serverSummaryReportLoader,
        },
        {
            path: '/cp/license',
            children: [
                {
                    index: true,
                    loader: () => redirect('primary'),
                },
                {
                    path: 'primary',
                    componentLoader: () => import(/* webpackChunkName: "views/license" */'components/pages/PrimaryKeyInfoPage'),
                    loader: async () => {
                        await apolloClient.query({
                            query: require('queries/PrimaryKeyInfoPage.graphql'),
                        });
                        await apolloClient.query({
                            query: require('queries/MasterKeyProcessingState.graphql'),
                        });
                    },
                    isExist: async () => {
                        const { data: { viewer: { type, permissions } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerLicense
                                        }
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && permissions.manageServerLicense;
                    },
                    children: [
                        {
                            path: 'upload',
                            componentLoader: () => import(/* webpackChunkName: "views/license" */'components/pages/PrimaryKeyUploadPage'),
                            loader: async () => {
                                await apolloClient.query({
                                    query: require('queries/PrimaryKeyUploadPage.graphql'),
                                });
                            },
                            isExist: async () => {
                                const { data: { viewer: { type, permissions }, mode: { isDemo } } } = await apolloClient.query({
                                    query: gql`
                                        query {
                                            viewer {
                                                login
                                                type
                                                permissions {
                                                    manageServerLicense
                                                }
                                            }
                                            mode {
                                                isDemo
                                            }
                                        }
                                    `,
                                });

                                return type === 'ADMIN' && permissions.manageServerLicense && !isDemo;
                            },
                        },
                        {
                            path: 'revert',
                            componentLoader: () => import(/* webpackChunkName: "views/license" */'components/pages/PrimaryKeyRevertPage'),
                            loader: async () => {
                                await apolloClient.query({
                                    query: require('queries/PrimaryKeyRevertPage.graphql'),
                                });
                            },
                            isExist: async () => {
                                const { data: { viewer: { type, permissions }, mode: { isDemo } } } = await apolloClient.query({
                                    query: gql`
                                        query {
                                            viewer {
                                                login
                                                type
                                                permissions {
                                                    manageServerLicense
                                                }
                                            }
                                            mode {
                                                isDemo
                                            }
                                        }
                                    `,
                                });

                                return type === 'ADMIN' && permissions.manageServerLicense && !isDemo;
                            },
                        },
                    ],
                },
                {
                    path: 'additional',
                    componentLoader: () => import(/* webpackChunkName: "views/license/additional" */'components/pages/AdditionalKeysPage'),
                    loader: async () => {
                        await apolloClient.query({
                            query: require('queries/AdditionalKeysPage.graphql'),
                        });
                    },
                    isExist: async () => {
                        const { data: { viewer: { type, permissions } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerLicense
                                        }
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && permissions.manageServerLicense;
                    },
                    children: [
                        {
                            path: 'upload',
                            componentLoader: () => import(/* webpackChunkName: "views/license/additional" */'components/pages/AdditionalKeyUploadPage'),
                            loader: async () => {
                                await apolloClient.query({
                                    query: require('queries/AdditionalKeyUploadPage.graphql'),
                                });
                            },
                            isExist: async () => {
                                const { data: { viewer: { type, permissions }, mode: { isDemo } } } = await apolloClient.query({
                                    query: gql`
                                        query {
                                            viewer {
                                                login
                                                type
                                                permissions {
                                                    manageServerLicense
                                                }
                                            }
                                            mode {
                                                isDemo
                                            }
                                        }
                                    `,
                                });

                                return type === 'ADMIN' && permissions.manageServerLicense && !isDemo;
                            },
                        },
                    ],
                },
                {
                    path: 'additional/:id',
                    componentLoader: () => import(/* webpackChunkName: "views/license/additional" */'components/pages/AdditionalKeyInfoPage'),
                    loader: async ({ params: { id } }) => {
                        await apolloClient.query({
                            query: require('queries/AdditionalKeyInfoPage.graphql'),
                            variables: { id },
                        });
                    },
                    isExist: async ({ params }) => {
                        const { data: { viewer: { type, permissions }, additionalKey } } = await apolloClient.query({
                            query: gql`
                                query ($id: ID!) {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerLicense
                                        }
                                    }
                                    additionalKey(id: $id) {
                                        keyNumber
                                    }
                                }
                            `,
                            variables: {
                                id: params.id,
                            },
                        });

                        return additionalKey && type === 'ADMIN' && permissions.manageServerLicense;
                    },
                    children: [
                        {
                            path: 'revert',
                            componentLoader: () => import(/* webpackChunkName: "views/license/additional" */'components/pages/AdditionalKeyRevertPage'),
                            loader: async ({ params: { id } }) => {
                                await apolloClient.query({
                                    query: require('queries/AdditionalKeyRevertPage.graphql'),
                                    variables: { id },
                                });
                            },
                            isExist: async ({ params }) => {
                                const { data: { viewer: { type, permissions }, mode: { isDemo }, additionalKey } } = await apolloClient.query({
                                    query: gql`
                                        query ($id: ID!) {
                                            viewer {
                                                login
                                                type
                                                permissions {
                                                    manageServerLicense
                                                }
                                            }
                                            additionalKey(id: $id) {
                                                keyNumber
                                            }
                                            mode {
                                                isDemo
                                            }
                                        }
                                    `,
                                    variables: {
                                        id: params.id,
                                    },
                                });

                                return additionalKey && type === 'ADMIN' && permissions.manageServerLicense && !isDemo;
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '/cp/firewall',
            children: [
                {
                    index: true,
                    loader: () => redirect('general'),
                },
                {
                    path: 'general',
                    componentLoader: () => import(/* webpackChunkName: "components/pages/FirewallGeneralPage" */'components/pages/FirewallGeneralPage'),
                    loader: async () => {
                        await apolloClient.query({
                            query: require('queries/FirewallGeneralPage.graphql'),
                        });
                    },
                    isExist: async () => {
                        const { data: { viewer: { type, permissions }, os: { isUnix }, mode: { isDemo } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerFirewall
                                        }
                                    }
                                    mode {
                                        isDemo
                                    }
                                    os {
                                        isUnix
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && !isUnix && !isDemo && permissions.manageServerFirewall;
                    },
                },
                {
                    path: 'icmp',
                    componentLoader: () => import(/* webpackChunkName: "components/pages/FirewallIcmpPage" */'components/pages/FirewallIcmpPage'),
                    loader: async () => {
                        await apolloClient.query({
                            query: require('queries/FirewallIcmpPage.graphql'),
                        });
                    },
                    isExist: async () => {
                        const { data: { viewer: { type, permissions }, os: { isUnix }, mode: { isDemo } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerFirewall
                                        }
                                    }
                                    mode {
                                        isDemo
                                    }
                                    os {
                                        isUnix
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && !isUnix && !isDemo && permissions.manageServerFirewall;
                    },
                },
                {
                    path: 'rules',
                    componentLoader: () => import(/* webpackChunkName: "components/pages/FirewallRulesPage" */'components/pages/FirewallRulesPage'),
                    loader: async () => {
                        await apolloClient.query({
                            query: require('queries/FirewallRulesPage.graphql'),
                        });
                    },
                    isExist: async () => {
                        const { data: { viewer: { type, permissions }, os: { isUnix }, mode: { isDemo } } } = await apolloClient.query({
                            query: gql`
                                query {
                                    viewer {
                                        login
                                        type
                                        permissions {
                                            manageServerFirewall
                                        }
                                    }
                                    mode {
                                        isDemo
                                    }
                                    os {
                                        isUnix
                                    }
                                }
                            `,
                        });

                        return type === 'ADMIN' && !isUnix && !isDemo && permissions.manageServerFirewall;
                    },
                },
            ],
        },
        {
            path: ['/cp/server/components', '/cp/server/components/default/:name', '/cp/server/components/configuration/:name'],
            componentLoader: () => import(/* webpackChunkName: "components/pages/ServerComponentsPage" */'components/pages/ServerComponentsPage'),
            loader: async () => {
                await apolloClient.query({
                    query: require('queries/ServerComponentsPage.graphql'),
                });
            },
            isExist: async () => {
                const { data: { viewer: { type, permissions } } } = await apolloClient.query({
                    query: gql`
                        query {
                            viewer {
                                login
                                type
                                permissions {
                                    manageServerComponents
                                }
                            }
                        }
                    `,
                });

                return type === 'ADMIN' && permissions.manageServerComponents;
            },
        },
        {
            path: '/cp/ip-address/',
            componentLoader: () => import(/* webpackChunkName: "views/admin/ip-address/list" */'components/pages/IpAddressPage'),
            loader: () => apolloClient.query({
                query: require('queries/IpAddressPage.graphql'),
                variables: { page: 1, itemsPerPage: 25 },
            }).then(({ data }) => data),
            children: [
                { path: ['add', ':id/edit'] },
                ipAddressDomainRoute,
            ],
        },
        {
            path: '/cp/ip-address/:id/clients',
            componentLoader: () => import(/* webpackChunkName: "views/admin/ip-address/clients" */'components/pages/IpAddressResellersPage'),
            loader: ({ params: { id } }) => apolloClient.query({
                query: require('components/pages/IpAddressResellersPage/IpAddressResellerList.graphql'),
                variables: { id },
            }),
            children: [{
                path: 'assign',
                componentLoader: () => import(
                    /* webpackChunkName: "components/pages/AssignIpAddressToResellerForm" */
                    'components/pages/IpAddressResellersPage/AssignIpAddressToResellerForm'
                ),
                loader: ({ params: { id } }) => apolloClient.query({
                    query: require('components/pages/IpAddressResellersPage/AssignIpAddressToResellerForm/UnassignedIpAddressResellers.graphql'),
                    variables: { id },
                }),
            }],
        },
        {
            path: '/cp/client/:clientId/ip-address',
            componentLoader: () => import(/* webpackChunkName: "views/admin/client/ip-address" */ 'components/pages/ClientIpAddressPage'),
            loader: async ({ params: { clientId } }) => {
                await apolloClient.query({
                    query: require('queries/ClientIpAddress.graphql'),
                    variables: { clientId: btoa(`Client:${clientId}`) },
                });
            },
            children: [ipAddressDomainRoute, {
                path: ':ipAddressId/properties',
                componentLoader: () => import(
                    /* webpackChunkName: "views/admin/client/ip-address/form" */ 'components/pages/ClientIpAddressPage/ClientIpAddressProperties'
                ),
                loader: async ({ params: { clientId, ipAddressId } }) => {
                    await apolloClient.query({
                        query: require('components/pages/ClientIpAddressPage/ClietnIpAddressProperties.graphql'),
                        variables: {
                            ipAddressId,
                            clientId: btoa(`Client:${clientId}`),
                        },
                    });
                },
            }, {
                path: 'assign',
                componentLoader: () => import(/* webpackChunkName: "views/admin/client/ip-address/form" */ 'components/pages/ClientIpAddressPage/AssignClientIpAddress'),
                loader: async ({ params: { clientId } }) => {
                    await apolloClient.query({
                        query: require('components/pages/ClientIpAddressPage/AssignClientIpAddress/AssignClientIpAddressDrawer.graphql'),
                        variables: {
                            clientId: btoa(`Client:${clientId}`),
                        },
                    });
                },
            }],
        },
        {
            path: '/cp/client/:clientId/summary-report',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/SummaryReportPage/ClientSummaryReportPage" */'components/pages/SummaryReportPage/ClientSummaryReportPage'),
            loader: clientSummaryReportLoader,
        },
        {
            path: '/cp/client/:clientId/summary-report/print',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/SummaryReportPage/ClientSummaryReportPrintPage" */'components/pages/SummaryReportPage/ClientSummaryReportPrintPage'),
            loader: clientSummaryReportLoader,
        },
        {
            path: '/cp/client/:clientId/summary-report/layouts',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/SummaryReportLayoutsPage/ClientSummaryReportLayoutsPage" */
                'components/pages/SummaryReportLayoutsPage/ClientSummaryReportLayoutsPage'
            ),

            loader: async ({ params: { clientId } }) => {
                await apolloClient.query({
                    query: require('queries/ClientSummaryReportLayoutsPage.graphql'),
                    variables: { clientId: btoa(`Client:${clientId}`) },
                });
            },
        },
        {
            path: '/cp/client/:clientId/summary-report/traffic-history',
            componentLoader: () => import(/* webpackChunkName: "components/pages/ClientTrafficHistoryReportPage" */'components/pages/ClientTrafficHistoryReportPage'),
            loader: async ({ params: { clientId } }) => {
                await apolloClient.query({
                    query: require('queries/ClientTrafficHistoryReportPage.graphql'),
                    variables: { clientId: btoa(`Client:${clientId}`) },
                });
            },
        },
        {
            path: '/cp/php/extensions/id/:id',
            componentLoader: () => import(/* webpackChunkName: "views/php/extensions" */'components/pages/PhpExtensionsPage'),
            loader: async ({ params: { id } }) => {
                await apolloClient.query({
                    query: require('queries/PhpExtensionsPage.graphql'),
                    variables: { id },
                });
            },
            isExist: async ({ params }) => {
                const { data: { viewer: { type, permissions }, os: { isWindows }, phpHandler: { cliPath } } } = await apolloClient.query({
                    query: gql`
                        query($id: ID!) {
                            viewer {
                                login
                                type
                                permissions {
                                    manageServerPhp
                                }
                            }
                            os {
                                isWindows
                            }
                            phpHandler(id: $id) {
                                id
                                cliPath
                            }
                        }
                    `,
                    variables: {
                        id: params.id,
                    },
                });

                return type === 'ADMIN' && permissions.manageServerPhp && (isWindows || cliPath !== '');
            },
        },
        {
            path: '/cp/server/ts-sessions/:id',
            componentLoader: () => import(/* webpackChunkName: "components/pages/TsSessionPage" */'components/pages/TsSessionPage'),
            loader: ({ params }) => async () => {
                await apolloClient.query({
                    query: require('queries/TsSessionPage.graphql'),
                    variables: { ids: [params.id] },
                });
            },
            isExist: isTsSessionsExist,
        },
        {
            path: '/cp/server/ts-sessions',
            componentLoader: () => import(/* webpackChunkName: "components/pages/TsSessionsPage" */'components/pages/TsSessionsPage'),
            loader: async () => {
                await apolloClient.query({
                    query: require('queries/TsSessionsPage.graphql'),
                });
            },
            isExist: isTsSessionsExist,
        },
        odbcDomainRoute,
        odbcServerRoute,
        {
            path: '/cp/server/asp-dot-net-settings',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/AspDotNetSettingsPage/AspDotNetServerSettingsPage" */'components/pages/AspDotNetSettingsPage/AspDotNetServerSettingsPage'),
            loader: async () => {
                const { data } = await apolloClient.query({
                    query: require('queries/AspDotNetServerSettingsPage.graphql'),
                });
                return data;
            },
            isExist: isAspPageExist,
        },
        {
            path: '/cp/domains/:domainId/asp-dot-net-settings',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/AspDotNetSettingsPage/AspDotNetDomainSettingsPage" */'components/pages/AspDotNetSettingsPage/AspDotNetDomainSettingsPage'),
            loader: async ({ request, params }) => {
                const url = new URL(request.url);
                const webPath = url.searchParams.get('webPath') || '';
                const { data } = await apolloClient.query({
                    query: require('queries/AspDotNetDomainSettingsPage.graphql'),
                    variables: { id: btoa(`Domain:${params.domainId}`), webPath },
                });
                return data;
            },
            isExist: async ({ params }) => {
                const { data: { domain: { webScriptingSettings, aspNetSettings } } } = await apolloClient.query({
                    query: gql`
                        query($id: ID!) {
                            domain: node(id: $id) {
                                ... on Domain {
                                    webScriptingSettings {
                                        aspDotNet
                                    }
                                    aspNetSettings {
                                        configs {
                                            version
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        id: btoa(`Domain:${params.domainId}`),
                    },
                });


                return webScriptingSettings !== null && webScriptingSettings.aspDotNet !== null && aspNetSettings.configs.length;
            },
        },
        {
            path: 'cp',
            children: [
                {
                    element: <CpPagesLayout />,
                    loader: () => pageData(),
                    children: [
                        {
                            path: 'server',
                            children: [
                                {
                                    index: true,
                                    loader: () => redirect('/admin/server/tools'),
                                },
                                {
                                    path: 'about',
                                    componentLoader: () => import(/* webpackChunkName: "components/pages/ServerAboutPage" */'components/pages/ServerAboutPage'),
                                    loader: async () => {
                                        await apolloClient.query({
                                            query: require('queries/ServerAboutPage.graphql'),
                                        });
                                    },
                                    isExist: async () => {
                                        const { data: { viewer } } = await apolloClient.query({
                                            query: gql`
                                                query {
                                                    viewer {
                                                        login
                                                        type
                                                    }
                                                }
                                            `,
                                        });

                                        return viewer.type === 'ADMIN';
                                    },
                                },
                                {
                                    path: 'action-log',
                                    componentLoader: () => import(/* webpackChunkName: "components/pages/ActionLogPage" */'components/pages/ActionLogPage'),
                                    loader: async () => {
                                        await apolloClient.query({
                                            query: require('queries/ActionLogPage.graphql'),
                                        });
                                    },
                                },
                                {
                                    path: 'settings',
                                    componentLoader: () => import(/* webpackChunkName: "components/pages/ServerSettingsPage" */'components/pages/ServerSettingsPage'),
                                    loader: async () => {
                                        await apolloClient.query({
                                            query: require('queries/ServerSettingsPage.graphql'),
                                        });
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: '*',
                    loader: () => {
                        throw new Response('Not Found', { status: 404 });
                    },
                },
            ],
        },
        {
            id: 'php-pages-layout',
            path: '*',
            element: <PhpPagesLayout />,
            children: [
                {
                    path: 'smb/log-file/browser/id/:id',
                    element: <LogsPage />,
                },
            ],
        },
    ],
}]);

export const isClientSideRedirectAllowed = to => {
    if (window.location.pathname.match(/^\/modules\/(warden)\//)) {
        return false;
    }

    const { pathname, search } = typeof to === 'string' ? parsePath(to) : to;
    if (search?.startsWith('?modals')) {
        return true;
    }
    if (!pathname) {
        return false;
    }

    let route = pathname;

    // relative path
    if (/^(?![\/?])/.test(to)) {
        route = window.location.pathname + route;
    }

    const matches = matchRoutes(routes, route);
    return matches ? !matches.some(({ route }) => route.id === 'php-pages-layout') : false;
};

export default routes;
